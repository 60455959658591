var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('ResourceList',{attrs:{"can-create":false,"can-select-many":false,"data":_vm.data,"has-buttons":false,"is-deleting":_vm.isDeleting,"is-loading":_vm.isLoading,"pagination":_vm.pagination,"search-query":_vm.$route.query ? _vm.$route.query.query : '',"resource":"supply order notification","resource-plural":"notifications","title":"Supply Order Notifications"},on:{"paginate":_vm.onPaginate,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column flex-fill"},[(item.completed === 0)?_c('div',{staticClass:"d-flex flex-row flex-fill"},[_c('p',{staticClass:"h5 flex-fill text-primary"},[_c('router-link',{staticClass:"text-dark",attrs:{"to":{name: 'dash.orders.view', params: { id: item.id } }}},[_vm._v("#"+_vm._s(item.id)+" "+_vm._s(item.customer.name)+" ")])],1),_c('p',{staticClass:"align-self-end mb-0 text-muted"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover",value:(
                'View more information about this order.'
              ),expression:"\n                'View more information about this order.'\n              "}],staticClass:"mr-1 mb-0",attrs:{"to":{
                name: 'dash.orders.view',
                params: { id: item.id }
              },"size":"sm","variant":"primary"}},[_c('b-icon',{attrs:{"icon":"cart"}}),_vm._v(" View Order ")],1)],1)]):_vm._e(),_c('div',[_c('small',{staticClass:"text-muted"},[_c('FriendlyDate',{attrs:{"date":item.created_at}})],1)]),_c('div',[_c('hr')]),_c('div',{staticClass:"d-flex flex-row flex-fill mb-0 mb-md-1"},[_c('b-card',{staticClass:"w-100"},[_c('h6',[_vm._v("Supply Order Notifications:")]),_c('ul',{staticClass:"list-group mt-3"},_vm._l((item.supply_order_notifications),function(supplyOrderNotification,supplyOrderNotificationIndex){return _c('li',{key:("notification-" + (item.id) + "-" + (supplyOrderNotification.id)),staticClass:"list-group-item"},[_c('p',{staticClass:"mb-0"},[_c('router-link',{attrs:{"to":{
                      name: 'dash.supply_orders.view',
                      params: {
                        id: supplyOrderNotification.supply_order_id
                      }
                    }}},[_vm._v(" #"+_vm._s(supplyOrderNotification.supply_order_id)+" - "+_vm._s(supplyOrderNotification.supply_order.name)+" ")])],1),(
                    !item.order_items[supplyOrderNotificationIndex] ||
                      !item.order_items[supplyOrderNotificationIndex]
                        .product ||
                      !item.order_items[supplyOrderNotificationIndex].product
                        .range
                  )?_c('small',{staticClass:"text-muted d-block"},[_vm._v("No Range")]):_c('small',{staticClass:"text-muted d-block"},[_vm._v(" Range: "+_vm._s(item.order_items[supplyOrderNotificationIndex].product .range)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Created recently.")]),_c('hr'),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover",value:(
                    'View more information about this supply order.'
                  ),expression:"\n                    'View more information about this supply order.'\n                  "}],staticClass:"mr-1 mb-0",attrs:{"to":{
                    name: 'dash.supply_orders.view',
                    params: {
                      id: supplyOrderNotification.supply_order_id
                    }
                  },"size":"sm","variant":"primary"}},[_c('b-icon',{attrs:{"icon":"eye"}}),_vm._v(" View Supply Order ")],1),(_vm.hasAnyPermission(['dismiss-notifications']))?_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover",value:('Dismiss this notification.'),expression:"'Dismiss this notification.'"}],staticClass:"mr-1 mb-0",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.doDismissNotification(supplyOrderNotification.id)}}},[_c('fa-icon',{attrs:{"icon":"bell-slash"}}),_vm._v(" Dismiss Notification ")],1):_vm._e()],1)}),0)])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }