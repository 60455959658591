<template>
  <b-container>
    <ResourceList :can-delete="hasAnyPermission(['delete-supply-order'])" :data="data"
                  :has-buttons="false" :has-filters="true" :is-deleting="isDeleting"
                  :is-loading="isLoading" :pagination="pagination" :search-query="$route.query.query"
                  create-text="Supply Order"
                  create-to="dash.supply_orders.create" resource="supply order" resource-plural="supply orders"
                  title="Supply Orders" @paginate="onPaginate" @search="onSearch" @delete-many="onDeleteMany">
      <template #filters>
        <b-row>
          <b-col cols="12">
            <b-form-select v-model="status" @input="doPaginate">
              <b-form-select-option :value="null">Please select a status</b-form-select-option>
              <b-form-select-option value="Goods In">Goods In</b-form-select-option>
              <b-form-select-option value="Partial Goods In">Partial Goods In</b-form-select-option>
              <b-form-select-option value="Ordered">Ordered</b-form-select-option>
              <b-form-select-option value="Not Ordered">Not Ordered</b-form-select-option>
              <b-form-select-option value="Faulted">Faulted</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary">
              <router-link :to="{ name: 'dash.supply_orders.view', params: { id: item.id } }" class="text-dark">
                #{{ item.id }} {{ item.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-0 text-muted">Created:
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>
          <div class="d-flex flex-row flex-fill mt-2">
            <b-button v-b-popover.hover.bottom="'View more information about this supply order.'"
                      :to="{ name: 'dash.supply_orders.view', params: { id: item.id } }"
                      class="mr-1 mb-0"
                      size="sm"
                      variant="primary">
              <b-icon icon="eye"/>
              View
            </b-button>
            <b-button v-if="hasAnyPermission(['update-supply-order'])"
                      v-b-popover.hover.bottom="'Edit this supply order'"
                      :to="{ name: 'dash.supply_orders.update', params: { id: item.id } }" class="mr-1 mb-0"
                      size="sm"
                      variant="secondary">
              <b-icon icon="pen"/>
              Edit
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [currentUser],
  created() {
    this.fetchMany(this.$route.query)
  },
  components: {
    ResourceList,
    FriendlyDate
  },
  data: () => ({
    status: null
  }),
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("supply-orders", ["data", "isDeleting", "isLoading", "pagination"])
  },
  methods: {
    ...mapActions("supply-orders", ["deleteMany", "fetchMany"]),

    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids})
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async doPaginate() {
      await this.fetchMany({
        ...this.$route.query,
        status: this.status
      })
    },

    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({
          name: this.$route.name,
          query: {...this.$route.query, page, status: this.status}
        }).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page, status: this.status}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query, status: this.status}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },

  }
}
</script>
<style>
</style>
