<template>
	<router-view />
</template>

<script>
import productModule from "../../store/products"
import supplyReturnModule from "../../store/supply-returns"

export default {
	beforeCreate() {
		this.$store.registerModule("products", productModule)
		this.$store.registerModule("supply-returns", supplyReturnModule)
	},
	destroyed() {
		this.$store.unregisterModule("products")
		this.$store.unregisterModule("supply-returns")
	},
}
</script>