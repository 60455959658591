<template>
  <b-container>
    <ResourceList
      :can-create="false"
      :can-select-many="false"
      :data="data"
      :has-buttons="false"
      :is-deleting="isDeleting"
      :is-loading="isLoading"
      :pagination="pagination"
      :search-query="$route.query ? $route.query.query : ''"
      resource="supply order notification"
      resource-plural="notifications"
      title="Supply Order Notifications"
      @paginate="onPaginate"
      @search="onSearch">

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div v-if="item.completed === 0" class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill text-primary">
              <router-link
                :to="{name: 'dash.orders.view', params: { id: item.id } }" class="text-dark">#{{ item.id }}
                {{ item.customer.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-0 text-muted">
              <b-button v-b-popover="
                  'View more information about this order.'
                "
                        :to="{
                  name: 'dash.orders.view',
                  params: { id: item.id }
                }"
                        class="mr-1 mb-0"
                        size="sm"
                        variant="primary"
              >
                <b-icon icon="cart"/>
                View Order
              </b-button>
            </p>
          </div>
          <div>
            <small class="text-muted">
              <FriendlyDate :date="item.created_at"/>
            </small>
          </div>

          <div>
            <hr/>
          </div>

          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <b-card class="w-100">
              <h6>Supply Order Notifications:</h6>
              <ul class="list-group mt-3">
                <li
                  v-for="(supplyOrderNotification,
                  supplyOrderNotificationIndex) in item.supply_order_notifications"
                  :key="`notification-${item.id}-${supplyOrderNotification.id}`"
                  class="list-group-item"
                >
                  <p class="mb-0">
                    <router-link
                      :to="{
                        name: 'dash.supply_orders.view',
                        params: {
                          id: supplyOrderNotification.supply_order_id
                        }
                      }"
                    >
                      #{{ supplyOrderNotification.supply_order_id }}
                      -
                      {{ supplyOrderNotification.supply_order.name }}
                    </router-link>
                  </p>
                  <small
                    v-if="
                      !item.order_items[supplyOrderNotificationIndex] ||
                        !item.order_items[supplyOrderNotificationIndex]
                          .product ||
                        !item.order_items[supplyOrderNotificationIndex].product
                          .range
                    "
                    class="text-muted d-block"
                  >No Range</small
                  >
                  <small v-else class="text-muted d-block">
                    Range:
                    {{
                      item.order_items[supplyOrderNotificationIndex].product
                        .range
                    }}
                  </small>
                  <small class="text-muted">Created recently.</small>
                  <hr/>
                  <b-button
                    v-b-popover="
                      'View more information about this supply order.'
                    "
                    :to="{
                      name: 'dash.supply_orders.view',
                      params: {
                        id: supplyOrderNotification.supply_order_id
                      }
                    }"
                    class="mr-1 mb-0"
                    size="sm"
                    variant="primary"
                  >
                    <b-icon icon="eye"/>
                    View Supply Order
                  </b-button>
                  <b-button
                    v-if="hasAnyPermission(['dismiss-notifications'])"
                    v-b-popover="'Dismiss this notification.'"
                    class="mr-1 mb-0"
                    size="sm"
                    variant="secondary"
                    @click="doDismissNotification(supplyOrderNotification.id)"
                  >
                    <fa-icon icon="bell-slash"/>
                    Dismiss Notification
                  </b-button>
                </li>
              </ul>
            </b-card>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList";
import currentUser from "../../mixins/currentUser";
import {mapActions, mapGetters} from "vuex";
import FriendlyDate from "../../components/FriendlyDate";

export default {
  mixins: [currentUser],
  created() {
    this.fetchNotifications(this.$route.query);
  },
  components: {
    ResourceList,
    FriendlyDate
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("supply-orders", [
      "data",
      "isDeleting",
      "isLoading",
      "pagination"
    ])
  },

  data() {
    return {
      supply_order_notifications: null,
    }
  },

  methods: {
    ...mapActions("supply-orders", [
      "fetchNotifications",
      "dismissNotification",
      "getNotificationCount"
    ]),
    async doPaginate() {
      await this.fetchNotifications({...this.$route.query}).catch(() => {
      });
    },

    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchNotifications({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchNotifications({...this.$route.query, query}).catch(() => {
      })
    },

    doDismissNotification(notificationId) {
      this.dismissNotification(notificationId).then(() => {
        this.doPaginate();
        this.getNotificationCount();
      });
    }
  }
};
</script>
<style></style>
