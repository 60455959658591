export const SUPPLY_RETURNS_BATCH_DELETE_REQUEST = 'SUPPLY_RETURNS_BATCH_DELETE_REQUEST'
export const SUPPLY_RETURNS_BATCH_DELETE_SUCCESS = 'SUPPLY_RETURNS_BATCH_DELETE_SUCCESS'
export const SUPPLY_RETURNS_BATCH_DELETE_FAILURE = 'SUPPLY_RETURNS_BATCH_DELETE_FAILURE'

export const SUPPLY_RETURNS_FETCH_MANY_REQUEST = 'SUPPLY_RETURNS_FETCH_MANY_REQUEST'
export const SUPPLY_RETURNS_FETCH_MANY_SUCCESS = 'SUPPLY_RETURNS_FETCH_MANY_SUCCESS'
export const SUPPLY_RETURNS_FETCH_MANY_FAILURE = 'SUPPLY_RETURNS_FETCH_MANY_FAILURE'

export const SUPPLY_RETURNS_FETCH_REQUEST = 'SUPPLY_RETURNS_FETCH_REQUEST'
export const SUPPLY_RETURNS_FETCH_SUCCESS = 'SUPPLY_RETURNS_FETCH_SUCCESS'
export const SUPPLY_RETURNS_FETCH_FAILURE = 'SUPPLY_RETURNS_FETCH_FAILURE'

export const SUPPLY_RETURNS_SAVE_REQUEST = 'SUPPLY_RETURNS_SAVE_REQUEST'
export const SUPPLY_RETURNS_SAVE_SUCCESS = 'SUPPLY_RETURNS_SAVE_SUCCESS'
export const SUPPLY_RETURNS_SAVE_FAILURE = 'SUPPLY_RETURNS_SAVE_FAILURE'

export const EMPTY_SUPPLY_RETURNS = 'EMPTY_SUPPLY_RETURNS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
