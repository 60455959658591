import {
    SUPPLY_RETURNS_BATCH_DELETE_REQUEST,
    SUPPLY_RETURNS_BATCH_DELETE_SUCCESS,
    SUPPLY_RETURNS_BATCH_DELETE_FAILURE,
    SUPPLY_RETURNS_FETCH_MANY_REQUEST,
    SUPPLY_RETURNS_FETCH_MANY_SUCCESS,
    SUPPLY_RETURNS_FETCH_MANY_FAILURE,
    SUPPLY_RETURNS_FETCH_REQUEST,
    SUPPLY_RETURNS_FETCH_SUCCESS,
    SUPPLY_RETURNS_FETCH_FAILURE,
    SUPPLY_RETURNS_SAVE_REQUEST,
    SUPPLY_RETURNS_SAVE_SUCCESS,
    SUPPLY_RETURNS_SAVE_FAILURE,
    EMPTY_SUPPLY_RETURNS,
    CLEAR_ERRORS
} from "./types";

export default {
    deleteMany({ commit }, { ids = [] }) {
        commit(SUPPLY_RETURNS_BATCH_DELETE_REQUEST);
        return window.axios
            .post("/supply-returns", { _method: "DELETE", ids })
            .then(response => {
                commit(SUPPLY_RETURNS_BATCH_DELETE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(SUPPLY_RETURNS_BATCH_DELETE_FAILURE, error);
                throw error;
            });
    },
    fetchMany({ commit }, { query = "", page = 1, status = null, extraParams = {} }) {
        commit(SUPPLY_RETURNS_FETCH_MANY_REQUEST);
        return window.axios
            .get("/supply-returns", { params: { query, page, status, ...extraParams } })
            .then(response => {
                commit(SUPPLY_RETURNS_FETCH_MANY_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(SUPPLY_RETURNS_FETCH_MANY_FAILURE, error);
                throw error;
            });
    },
    fetch({ commit }, id) {
        commit(SUPPLY_RETURNS_FETCH_REQUEST);
        return window.axios
            .get(`/supply-returns/${id}`)
            .then(response => {
                commit(SUPPLY_RETURNS_FETCH_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(SUPPLY_RETURNS_FETCH_FAILURE, error);
                throw error;
            });
    },
    save({ commit }, data) {
        commit(SUPPLY_RETURNS_SAVE_REQUEST);
        return window.axios.post('supply-returns', data)
            .then(response => {
                commit(SUPPLY_RETURNS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(SUPPLY_RETURNS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(SUPPLY_RETURNS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    empty({ commit }) {
        commit(EMPTY_SUPPLY_RETURNS);
    },
    clearErrors({ commit }) {
        commit(CLEAR_ERRORS);
    }
};
